var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pale-blue",staticStyle:{"background-color":"#48aaf912"}},[_c('v-card-title',{staticClass:"d-flex justify-center text-center"},[_c('p',{staticClass:"my-0 heading",staticStyle:{"width":"100%","color":"#ec2027"}},[_vm._v(" Diani Crystal Gardens ")])]),_c('v-row',[_c('v-col',{staticClass:"col-12 pa-2",staticStyle:{"position":"relative"}},[_c('span',{staticClass:"pointer",staticStyle:{"position":"absolute","top":"50%","left":"5%","z-index":"99"},on:{"click":_vm.previousPhoto}},[_c('v-icon',{staticClass:"rounded grey"},[_vm._v(" mdi-arrow-left ")])],1),(_vm.img_source == 'real_land_0.png')?_c('v-img',{staticClass:"img rounded lighten-4 ma-4",attrs:{"contain":"","src":require("../../assets/real_land_0.png")}}):_vm._e(),(_vm.img_source == 'real_land_1.png')?_c('v-img',{staticClass:"img rounded lighten-4 ma-4",attrs:{"contain":"","src":require("../../assets/real_land_1.png")}}):_vm._e(),(_vm.img_source == 'real_land_2.png')?_c('v-img',{staticClass:"img rounded lighten-4 ma-4",attrs:{"contain":"","src":require("../../assets/real_land_2.png")}}):_vm._e(),(_vm.img_source == 'real_land_3.png')?_c('v-img',{staticClass:"img rounded lighten-4 ma-4",attrs:{"contain":"","src":require("../../assets/real_land_3.png")}}):_vm._e(),(_vm.img_source == 'real_land_4.png')?_c('v-img',{staticClass:"img rounded lighten-4 ma-4",attrs:{"contain":"","src":require("../../assets/real_land_4.png")}}):_vm._e(),_c('span',{staticClass:"pointer",staticStyle:{"position":"absolute","top":"50%","right":"5%"},on:{"click":_vm.nextPhoto}},[_c('v-icon',{staticClass:"rounded grey"},[_vm._v(" mdi-arrow-right ")])],1)],1),_c('v-col',{staticClass:"col-12 d-flex justify-center "},[_c('v-img',{staticClass:"pointer rounded mx-1",class:{
            'red': _vm.img_source == 'real_land_0.png'
            },attrs:{"contain":"","width":30,"height":20,"src":require("../../assets/real_land_0.png")},on:{"click":function($event){return _vm.changeSource('real_land_0')}}}),_c('v-img',{staticClass:"pointer rounded mx-1",class:{
            'red': _vm.img_source == 'real_land_1.png'
            },attrs:{"contain":"","width":30,"height":20,"src":require("../../assets/real_land_1.png")},on:{"click":function($event){return _vm.changeSource('real_land_1')}}}),_c('v-img',{staticClass:"pointer rounded mx-1",class:{
            'red': _vm.img_source == 'real_land_2.png'
            },attrs:{"contain":"","width":30,"height":20,"src":require("../../assets/real_land_2.png")},on:{"click":function($event){return _vm.changeSource('real_land_2')}}}),_c('v-img',{staticClass:"pointer rounded mx-1",class:{
            'red': _vm.img_source == 'real_land_3.png'
            },attrs:{"contain":"","width":30,"height":20,"src":require("../../assets/real_land_3.png")},on:{"click":function($event){return _vm.changeSource('real_land_3')}}}),_c('v-img',{staticClass:"pointer rounded mx-1",class:{
            'red': _vm.img_source == 'real_land_4.png'
            },attrs:{"contain":"","width":30,"height":20,"src":require("../../assets/real_land_4.png")},on:{"click":function($event){return _vm.changeSource('real_land_4')}}})],1),_c('v-col',{staticClass:"col-12 px-4 text--bold",staticStyle:{"font-weight":"900"}},[_c('h3',{staticClass:"d-flex align-center mr-2"},[_c('v-icon',{staticClass:"red--text",attrs:{"small":""}},[_vm._v(" mdi-pin ")]),_vm._v(" Diani ")],1),_c('h5',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"green--text mx-1",attrs:{"x-small":""}},[_vm._v(" mdi-circle ")]),_vm._v(" "+_vm._s("400M off tamarc.")+" ")],1),_c('h5',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"green--text mx-1",attrs:{"x-small":""}},[_vm._v(" mdi-circle ")]),_vm._v(" "+_vm._s("1KM from Technical University of Mombasa.")+" ")],1),_c('h5',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"green--text mx-1",attrs:{"x-small":""}},[_vm._v(" mdi-circle ")]),_vm._v(" "+_vm._s("15Min Drive to Ukunda Airstrip.")+" ")],1),_c('h5',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"green--text mx-1",attrs:{"x-small":""}},[_vm._v(" mdi-circle ")]),_vm._v(" "+_vm._s("20Min Drive to Diani Beach.")+" ")],1),_c('h5',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"green--text mx-1",attrs:{"x-small":""}},[_vm._v(" mdi-circle ")]),_vm._v(" "+_vm._s("Water and Electricity on-site.")+" ")],1)]),_c('v-col',{staticClass:"col-12 d-flex px-4"},[_c('v-col',{staticClass:"col-5 offset-1"},[_c('span',{staticClass:"d-flex mr-2 cta tex-center d-flex justify-center align-center red rounded white--text"},[_c('span',{staticClass:"body-text px-3"},[_vm._v(" "+_vm._s("350,000")+" ")])])]),_c('v-col',{staticClass:"col-5 offset-1"},[_c('span',{staticClass:"d-flex mr-2 pointer cta tex-center d-flex justify-center align-center red rounded white--text",on:{"click":function($event){return _vm.go('property/diani-crystal-gardens')}}},[_c('span',{staticClass:"body-text px-3"},[_vm._v(" "+_vm._s("View")+" ")])])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }